import { defaultLeadsApiCall } from '@itau-loans-www/shopping/src/services/http/utils'

const getPhoneNumber = async () => {
  const { data } = await defaultLeadsApiCall({
    endpoint: 'phone-number',
    method: 'get'
  })

  return data
}
const putPhoneNumber = async ({ phone }) => {
  const payload = { phone_number: phone }

  const { data } = await defaultLeadsApiCall({
    endpoint: 'phone-number',
    method: 'put',
    body: payload
  })

  return data
}

export default { getPhoneNumber, putPhoneNumber }
